@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.custom-nav {
  background-color: $dark-grey;
  &__top {
    background-color: $grey;
    padding: 10px 0;
    p {
      color: #fff;
      text-transform: uppercase;
      text-align: center;
      font-size: 0.8rem;
      letter-spacing: 1.5px;
      @include breakpoint($md) {
        font-size: 0.9rem;
      }
    }
    .slick-container {
      overflow: hidden;
    }
  }
  .navbar__search {
    position: relative;
    margin-top: 10px;
    @include breakpoint($sm-only) {
      width: 100%;
    }
    @media (min-width: 1000px) {
      margin-top: -1rem;
      width: 900px;
    }
    width: 550px;
    @media (min-width: 1024px) and (max-height: 769px) {
      margin-top: -1rem;
      width: 800px;
    }
    // @include breakpoint($md) {
    //   width: 600px;
    // }
    @include breakpoint($lg) {
      margin-top: -1rem;
      width: 700px;
    }
    input {
      background: #f6f6f6;
      box-shadow: 0 2px 3px 0 rgba(106, 106, 106, 0.15);
      border: 1px solid #ebebeb;
      border-radius: 1px;
      font-family: $secondary-font;
      height: 45px;
      font-size: 1rem;
      position: relative;
      z-index: 9;
    }
    &__results {
      position: absolute;
      background-color: #fbfbfb;
      // box-shadow: 0 5px 8px 3px rgba(103, 55, 123, 0.18);

      // padding: 1rem;

      width: 100%;
      z-index: 3;
      top: 55px;

      &__container {
        max-height: 360px;
        overflow-y: scroll;
        z-index: 9;
        position: relative;
        @include breakpoint($md) {
          max-height: 300px;
        }
      }
      ::-webkit-scrollbar-thumb {
        background: #000 !important;
      }
      &.show {
        border-radius: 10px;
        border: 2px solid #c9c9c9;
        overflow-y: auto;
        // &::before {
        //   content: "";
        //   opacity: 0.5;
        //   position: fixed;
        //   top: 0;
        //   left: 0;
        //   background-color: #fff;
        //   z-index: 1;
        //   width: 100%;
        //   height: calc(110vh * 3.2);
        // }
      }
      a {
        font-family: $main-font;
        display: block;
        padding: 15px 10px;
        color: #000;
        text-decoration: none;
        font-size: 1.05rem;
        @include breakpoint($md) {
          font-size: 1.1rem;
          padding: 10px;
        }
        &:not(:last-child) {
          border-bottom: 1px solid #c9c9c9;
        }
        &:first-child {
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
        &:last-child {
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }
        &:hover {
          background-color: rgb(243, 243, 243);
        }
        img {
          margin-right: 10px;
          display: none;
          @include breakpoint($md) {
            display: inline-block;
            max-height: 65px;
          }
        }
      }
      h6 {
        padding: 10px;
      }
    }
  }
  .navbar {
    &-brand {
      img {
        max-height: 50px;
        @include breakpoint($md) {
          max-height: 70px;
        }
      }
    }
    &-nav {
      a {
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-family: $secondary-font;
        &.active {
          color: #fff;
          font-family: $secondary-font-bold;
          // font-weight: bolder;
        }
      }
      p {
        color: #fff;
      }
      .show > .nav-link {
        color: #fff !important;
      }
    }
    &-toggler {
      border: none;

      // @media (max-width: 992px) {
      //   height: 50px;
      //   display: block;
      // }
      &-icon {
        background-image: url("../../assets/img/hamburguer-icon.svg") !important;
      }
    }
  }
  .nav {
    &-item {
    }
  }
  .dropdown {
    &-menu {
      border-radius: 0;
      padding: 1rem;
      border-bottom: 5px solid #000;
      @include breakpoint($md) {
        padding: 1.5rem;
        width: 500px;
      }
      h5 {
        font-size: 1.1rem;
        padding-bottom: 5px;
        margin-bottom: 5px;
        border-bottom: 1px solid #efefef;
      }
      a {
        color: #000;
        padding-left: 0;
        padding-right: 0;
        text-transform: capitalize;
        position: relative;
        &.active {
          background-color: #fff;
          color: #000;
          &::after {
            width: 70%;
          }
        }
        &:hover {
          background-color: #fff;
          &::after {
            width: 70%;
          }
        }
        &::after {
          content: "";
          position: absolute;
          bottom: 5px;
          left: 0;
          background-color: #000;
          height: 2px;
          width: 0;
          transition: all 0.2s ease-in-out;
        }
      }
    }
  }
  &__actions {
    display: none;
    @include breakpoint($md) {
      display: flex;
      margin-left: auto;
      align-items: center;
    }

    p {
      color: #fff;
      margin-left: 1rem;
      position: relative;
      font-size: 0.9rem;
      &:hover {
        cursor: pointer;
        &::after {
          width: 70%;
        }
      }
      &::after {
        content: "";
        position: absolute;
        bottom: -5px;
        left: 0;
        background-color: #fff;
        height: 2px;
        width: 0;
        transition: all 0.2s ease-in-out;
      }
    }
    &__cart {
      position: relative;
      span {
        background-color: #fff;
        color: #000;
        height: 20px;
        display: flex;
        justify-content: center;
        position: absolute;
        top: -5px;
        right: -10px;
        width: 20px;
        border-radius: 50%;
        font-weight: bolder;
        font-size: 0.95rem;
      }
    }
    &--mobile {
      display: inline-flex;
      margin-left: 4rem;
      @include breakpoint($md) {
        display: none;
      }
      img {
        @include breakpoint($md-down) {
          height: 26px;
        }
      }
      a {
        &.profile-cta {
          margin-right: 0.8rem;
        }
      }
    }
  }
}
