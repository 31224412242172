@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.cookies {
  &__wrapper {
  }
  &__hero {
    background-color: #eeeeee;
    .container-fluid {
      padding: 2.1rem 1rem;
      @include breakpoint($md) {
        padding: 3.1rem 2rem;
      }
    }
    h3 {
      margin-bottom: 0.5rem;
      @include breakpoint($md) {
        font-size: 2rem;
      }
    }
  }
  &__container {
    padding: 2rem 0;
    @include breakpoint($md) {
      padding: 4rem 0;
    }
    p {
      text-align: justify;
    }
    h4 {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    table {
      margin-top: 1rem;
    }
  }
}
