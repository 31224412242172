@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.confirmation {
  &__wrapper {
    background-color: #fbfbfb;
    padding: 2rem 1rem;
    display: flex;
    align-items: center;
    @include breakpoint($md) {
      padding: 2rem 0;
      min-height: 50vh;
    }
  }
  &__container {
    background-color: #fff;
    border-bottom: 6px solid #000;
    padding: 2rem 1rem;
    text-align: center;
    @include breakpoint($md) {
      padding: 2.1rem;
      margin: 0 auto;
      width: 50%;
    }
    img {
      margin-bottom: 10px;
    }
    h4 {
      margin: 5px 0;
      @include breakpoint($md) {
        margin: 10px 0;
      }
    }
    p {
      &:nth-child(2n) {
        margin: 10px 0;
        @include breakpoint($md) {
          margin: 10px auto;
          max-width: 75%;
        }
      }
    }
    a {
      background-color: #000;
      color: #fff;
      border: 0;
      padding: 0.8rem 2rem;
      width: max-content;
      margin: 0 auto;
      text-decoration: none;
      display: block;
      width: 180px;
      @include breakpoint($md) {
        width: 214px;
      }
    }
  }
}
