@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.alert {
  position: fixed;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
  text-align: center;
  width: 90%;
  @include breakpoint($md) {
    width: 60%;
  }
}
