$xs: (
  max: 375px,
);
$sm: (
  min: 400px,
);
$sm2: (
  min: 600px,
);
$md-1: (
  min: 860px,
);
$md: (
  min: 992px,
);
$md-2: (
  min: 1179px,
);
$md-3: (
  min: 1180px,
);
$md-down: (
  max: 991px,
);
$lg: (
  min: 1336px,
);
$lg2: (
  min: 1400px,
);
$xl: (
  min: 1900px,
);
$ipad-port: (
  min: 768px,
  max-h: 1024px,
);
$ipad-land: (
  min: 1024px,
  max-h: 768px,
);
$ipad-pro-port: (
  min: 1024px,
  max-h: 1366px,
);
$ipad-pro-land: (
  min: 1366px,
  max-h: 1024px,
);
$sm-only: (
  min: map-get($sm, min),
  max: map-get($md, min) - 1,
);
$md-only: (
  min: map-get($md, min),
  max: map-get($lg, min) - 1,
);

@mixin breakpoint($map) {
  $query: "";
  @if map-has-key($map, min) {
    $query: append($query, "(min-width: #{map-get($map, min)})");
  }
  @if map-has-key($map, min) and map-has-key($map, max) {
    $query: append($query, "and");
  }
  @if map-has-key($map, max) {
    $query: append($query, "(max-width: #{map-get($map, max)})");
  }
  @media screen and #{$query} {
    @content;
  }
}

@mixin flex-start() {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@mixin flex-end() {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@mixin flex-center() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin baseTransition($time) {
  -webkit-transition: all $time ease-in-out;
  -moz-transition: all $time ease-in-out;
  -ms-transition: all $time ease-in-out;
  -o-transition: all $time ease-in-out;
  transition: all $time ease-in-out;
}
