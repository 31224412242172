@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.cart {
  &__wrapper {
  }
  &__hero {
    background-color: #fbfbfb;
    .container-fluid {
      padding: 2rem 1rem;
      @include breakpoint($md) {
        padding: 3.1rem 2rem;
      }
    }
    h4 {
      font-size: 1.3rem;
      margin-bottom: 0.5rem;
      @include breakpoint($md) {
        font-size: 1.8rem;
      }
    }
  }
  &__container {
    background-color: #f5f4f2;
    padding: 1rem 0;
    @include breakpoint($lg) {
      padding: 2.3rem;
      min-height: 40vh;
    }
    a {
      color: #4b4b4b;
      margin-bottom: 1rem;
      display: block;
      font-size: 1rem;
      margin-left: auto;
      width: max-content;
      @include breakpoint($lg) {
        font-size: 1.1rem;
        margin-left: unset;
      }
    }
    &__products,
    &__summary {
      background-color: #fff;
      padding: 1rem;
      @include breakpoint($md) {
        padding: 1.5rem 1.5rem;
      }
      h5 {
        font-size: 1.15rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid #efefef;
      }
    }
    .container-fluid {
      @include breakpoint($lg) {
        padding: 0 3rem;
      }
    }
    &__products {
      border-bottom: 5px solid #000;
      &__list {
        .cart-product {
          margin-top: 1.3rem;
          @include breakpoint($md) {
            margin-top: 1rem;
          }
          &__media {
            img {
              height: auto;
              width: 100%;
              @include breakpoint($ipad-port) {
                max-width: 180px;
              }
              @include breakpoint($xl) {
                max-width: 200px;
              }
            }
          }
          &__details {
            p {
              text-transform: capitalize;
              @include breakpoint($md-down) {
                line-height: 1.2rem;
              }
              &:first-of-type {
                text-transform: uppercase;
                font-family: $secondary-font-bold;
                letter-spacing: 1.2px;
                line-height: 1.5rem;
                @include breakpoint($md) {
                  letter-spacing: 1.3px;
                }
              }
              span {
                color: #9e9e9e;
                font-size: 0.9rem;
              }
            }
            h6 {
              font-family: $secondary-font;
              font-size: 0.95rem;
              font-weight: bolder;
              margin: 5px 0;
            }
            &__presentation {
              text-transform: unset !important;
            }
          }
          &__quantity {
            display: flex;
            button {
              background-color: #d8d8d8;
              border: 0;
              font-weight: bolder;
              height: 35px;
              width: 35px;
              @include breakpoint($md) {
                &:hover {
                  background-color: darken(#d8d8d8, 10%);
                }
                &:disabled {
                  cursor: not-allowed;
                }
              }
            }
            span {
              background-color: #f6f6f6;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              height: 35px;
              text-align: center;
              width: 35px;
            }
          }
          &__action {
            display: flex;
            justify-content: space-between;
            @include breakpoint($md-down) {
              flex-grow: 1;
              justify-content: space-around;
            }
            h6 {
              font-size: 1.2rem;
              font-family: $secondary-font-semibold;
            }
            button {
              height: max-content;
              border: 0;
              background-color: unset;
              margin-left: 7px;
              @include breakpoint($xl) {
                margin-left: 2rem;
              }
            }
          }
        }
      }
    }
    &__summary {
      @include breakpoint($md) {
        position: sticky;
        position: -webkit-sticky;
        top: 1rem;
        left: 0 !important;
        right: unset;
        min-height: 280px;
      }
      h6 {
        font-family: $secondary-font-semibold;
        text-transform: uppercase;
        letter-spacing: 1px;
      }
      a {
        background-color: #000;
        color: #fff;
        display: block;
        text-align: center;
        text-decoration: none;
        padding: 1rem;
        transition: all 0.2s ease;
        width: 100%;
        &:hover {
          box-shadow: 0 5px 8px 3px rgba(170, 170, 170, 0.5);
        }
        @include breakpoint($md) {
          margin-top: 1.5rem;
        }
      }
    }
  }
}
