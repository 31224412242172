@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.login {
  &__wrapper {
    background-color: #f5f4f2;
    padding: 4rem 1rem;
    display: flex;
    align-items: center;
    @include breakpoint($md) {
      padding: 2rem 0;
      min-height: 60vh;
    }
  }
  &__container {
    background-color: #fff;
    border-top: 6px solid #000;
    padding: 2rem 1rem;
    margin: 0 auto;
    @include breakpoint($md-2) {
      padding: 2.1rem;
      width: 55%;
    }
    h4,
    p {
      @include breakpoint($md-down) {
        text-align: center;
      }
    }
    a {
      color: #000;
    }
    form {
      margin-top: 0.5rem;
    }
    label {
      font-weight: bolder;
    }
    input {
      background-color: #fbfbfb;
      border: 1px solid #dad8d8;
      border-radius: 0;
      padding: 0.8rem 0.6rem;
    }
    button {
      background-color: #000;
      border: 0;
      color: #fff;
      font-size: 1.1rem;
      padding: 1rem 0;
      margin: 1rem 0;
      width: 100%;
    }
  }
}
