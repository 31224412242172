@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.cookie-consent-banner {
  background-color: $dark-grey !important;
  flex-direction: column;
  height: auto;
  padding: 1rem !important;
  flex-wrap: nowrap !important;
  // background-color: $blue !important;
  @include breakpoint($md) {
    height: 190px !important;
    width: 60% !important;
    right: 0 !important;
    left: unset !important;
    padding: 1.5rem 1rem !important;
    flex-wrap: wrap;
    z-index: 9999999 !important;
  }
  &__header {
    h5 {
      margin-bottom: 7px;
    }
  }
  &__description {
    p {
      margin-bottom: 0;
      font-size: 1rem;
      @include breakpoint($md) {
        font-size: 0.9rem;
      }
    }
  }
  & > div {
    margin: 0 !important;
    // width: 100%;
    // &:nth-child(2n) {
    //   margin-top: 0 !important;
    // }
    flex: 1 0 0 !important;
    width: 100%;
  }
  a {
    display: inline-block;
    color: #fff;
    margin-left: 5px;
  }
  button {
    // margin: 0 15px 15px 15px !important;
    width: 100%;
    display: block;
    margin: 0 !important;
    background-color: #fff !important;
    color: #000 !important;
    font-weight: bolder;
    border: 0 !important;
    margin-top: 0.5rem !important;
    // margin: 0 15px !important;
    @include breakpoint($md) {
      font-size: 1.1rem;
      margin-top: 6px !important;
    }
  }
  // a {
  //   color: #fff;
  //   font-family: $main-font;
  //   font-weight: bolder;
  //   font-size: 1.05rem;
  //   margin-left: 5px;
  // }
}
