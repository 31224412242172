@import "./abstracts/variables";
@import "./abstracts/mixins";

body,
p,
span {
  font-family: $secondary-font;
  // margin-top: 134px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $main-font;
  margin-bottom: 0;
}

p {
  margin: 0;
}

.overlay {
  &.show {
    width: 100%;
    height: calc(110vh * 3.2);
    opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: 3;
    overflow-y: hidden;
    @include breakpoint($md) {
      height: 100%;
    }
  }
}

.StripeElement {
  padding: 0.8rem;
  border: 1px solid #dad8d8;
  border-radius: 6px;
  // font-family: $main-font !important;

  input {
    // font-family: $main-font !important;
    font-size: 1.1rem;
  }
  &--empty {
    // font-family: $main-font !important;
    border-color: #dc3545 !important;
    background-color: rgb(255, 240, 240);
  }
}
