// Color fonts
$grey: #a9a8a9;
$dark-grey: #1d1d1b;
$light-beige: #eceae8;

// Font variables
$main-font: "Playfair Display";
$secondary-font: "Proxima Nova";
$secondary-font-semibold: "Proxima Nova Semibold";
$secondary-font-bold: "Proxima Nova Bold";

@font-face {
  font-family: "Playfair Display";
  src: local("PlayfairDisplaySC"),
    url(../../assets/fonts/PlayfairDisplaySC-Regular.otf) format("opentype");
}
@font-face {
  font-family: "Proxima Nova Bold";
  src: local("ProximaNovaBold"),
    url(../../assets/fonts/Proxima-Nova-Bold.otf) format("opentype");
}
@font-face {
  font-family: "Proxima Nova Semibold";
  src: local("ProximaNovaSemibold"),
    url(../../assets/fonts/Proxima-Nova-Semibold.otf) format("opentype");
}
@font-face {
  font-family: "Proxima Nova";
  src: local("ProximaNova"),
    url(../../assets/fonts/proxima-nova.otf) format("opentype");
}
