@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.not-found {
  &__wrapper {
    background-color: #f5f4f2;
    padding: 4rem 1rem;
    display: flex;
    align-items: center;
    @include breakpoint($md) {
      padding: 2rem 0;
      min-height: 60vh;
    }
  }
  &__container {
    background-color: #fff;
    margin: 0 auto;
    text-align: center;
    padding: 2rem 1rem;
    @include breakpoint($md-2) {
      padding: 2.1rem;
      width: 55%;
    }
    h4 {
      margin-bottom: 1rem;
    }
    a {
      color: #000;
      font-weight: bolder;
      font-size: 1.1rem;
    }
    button {
      background-color: #000;
      color: #fff;
      border: 0;
      margin-top: 1rem;
      height: 50px;
      width: 180px;
    }
  }
}
