@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.product-individual {
  &__wrapper {
    background-color: #f5f4f2;
    padding: 2rem 1rem;
    @include breakpoint($md) {
      padding: 3.75rem 0;
    }
  }
  &__container {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    @include breakpoint($md) {
      flex-direction: row;
      max-width: 90%;
      margin: 0 auto;
    }
    @include breakpoint($xl) {
      max-width: 70%;
    }
  }
  &__media {
    @include breakpoint($ipad-land) {
      display: flex;
      align-items: center;
      min-width: 400px;
      max-width: 400px;
    }
    @include breakpoint($md-2) {
      min-width: 45%;
      max-width: 380px;
    }
    @include breakpoint($lg) {
      min-width: 540px;
      max-width: 580px;
    }
    @include breakpoint($xl) {
      min-width: 50%;
      max-width: 620px;
    }
    .slick-slider {
      width: 100%;
    }
    .slick-next,
    .slick-prev {
      background-color: #fff;
      border-radius: 100%;
      height: 23px;
      width: 25px;
      display: flex !important;
      align-items: center;
      justify-content: center;
      @include breakpoint($md) {
        height: 27px;
        width: 30px;
      }
    }
    .slick-prev:before,
    .slick-next:before {
      color: #000 !important;
      font-size: 2rem;
      opacity: 1;
      @include breakpoint($md) {
        font-size: 2.5rem;
        opacity: 1;
      }
    }
    .slick-next {
      top: 49.5%;
      right: 35px;
      z-index: 2;
      @include breakpoint($md-down) {
        top: 50%;
        right: 20px;
      }
    }
    .slick-prev {
      top: 50.1%;
      left: 25px;
      z-index: 2;
      @include breakpoint($md-down) {
        top: 50%;
        left: 10px;
      }
    }
    .slick-dots {
      @include breakpoint($md-down) {
        bottom: -20px;
      }
    }
    img {
      height: auto;
      width: 100%;
      @include breakpoint($lg) {
        width: 90%;
      }
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.5rem 1rem;
    @include breakpoint($md) {
      padding: 2.25rem;
      width: 100%;
    }
    &__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      @include breakpoint($md) {
        flex-direction: row;
        align-items: unset;
        // align-items: center;
        // margin-right: 1rem;
      }
      & > div {
        &:first-child {
          max-width: 70%;
        }
      }
      p {
        // margin-right: 0.5rem;
        order: 2;
        // margin-top: 0.5rem;
        text-transform: uppercase;
        font-family: $secondary-font-semibold;
        letter-spacing: 1.3px;
        font-size: 1.7rem;
        @include breakpoint($md) {
          font-size: 1.9rem;
          margin-right: 1rem;
          margin-top: 0;
          // order: 1;
        }
      }
      span {
        width: max-content;
        background-color: #000;
        color: #fff;
        padding: 0.4rem 0.6rem;
        font-size: 0.9rem;
        order: 1;
        display: block;
        margin-bottom: 0.5rem;
        @include breakpoint($md) {
          margin-right: 1rem;
          // order: 2;
        }
      }
      &__favorites {
        display: flex;
        flex-direction: column;
        align-items: center;
        @include breakpoint($md) {
          &:hover {
            cursor: pointer;
            .product-individual-favorite {
              background-color: #000;
              mask: url("../../assets/img/favorite-icon--black--filled.svg");
              -webkit-mask: url("../../assets/img/favorite-icon--black--filled.svg");
              mask-repeat: no-repeat;
              -webkit-mask-repeat: no-repeat;
            }
            p {
              text-decoration: underline;
            }
          }
        }
        // img {
        //   max-height: 30px;
        // }
        span {
          &.product-individual-favorite {
            // background-color: #000;
            // -webkit-mask-image: url("../../assets/img/favorite-icon--black.svg");
            // mask-image: url("../../assets/img/favorite-icon--black.svg");
            display: inline-block;
            width: 35px;
            height: 29px;
            // mask-repeat: no-repeat;
            // -webkit-mask-position: center;
            // mask-position: center;
            // -webkit-mask-repeat: no-repeat;
            margin: 0;
            mask: url("../../assets/img/favorite-icon--black.svg");
            -webkit-mask: url("../../assets/img/favorite-icon--black.svg");
            mask-repeat: no-repeat;
            -webkit-mask-repeat: no-repeat;
            &:hover {
              // background-color: #000;
              // fill: #000;

              // #noun-heart-5224441-copy {
              //   background-color: red;
              //   fill: red;
              // }
              // svg {
              //   fill: blue;
              //   background-color: red;
              // }
            }
          }
        }
        p {
          font-size: 0.9rem;
          text-transform: unset;
          color: #77777a;
          letter-spacing: 0;
          margin-right: 0;
          @include breakpoint($md) {
            font-size: 1rem;
          }
        }
      }
    }
    p {
      &:first-child {
        text-transform: capitalize;
        font-size: 1.9rem;
      }
    }
    &__details {
      display: flex;
      // margin-top: 0.8rem;
      margin-bottom: 0.5rem;
      justify-content: space-between;
      flex-wrap: wrap;
      & > div {
        display: flex;
        @include breakpoint($md) {
          flex-basis: 45%;
        }
        &:last-child {
          flex-basis: 100%;
        }
        &:not(:last-child) {
          margin-right: 0.8rem;
        }
        span {
          color: #757575;
          font-family: $main-font;
          font-size: 0.9rem;
          margin-right: 0.5rem;
        }
      }
    }
    &__presentation {
      & > div {
        display: flex;
        flex-direction: column;
        & > div {
          text-align: center;
          width: 55px;
          span {
            color: #757575;
            font-size: 0.9rem;
          }
        }
      }
      p {
        &.presentation {
          color: #757575;
          display: block;
          margin-bottom: 0.5rem;
          font-size: 1rem;
          text-transform: initial;
        }
      }
      input {
        display: none;
        &:checked + label {
          /* selected style */
          background-color: #5d7180;
          border: 1px solid #5d7180;
          color: #fff;
          transform: translateY(-5px);
          .sachetPresentation,
          .canPresentation,
          .sachet25Presentation {
            background-color: #fff;
          }
          @include breakpoint($md-down) {
            height: 55px;
            width: 55px;
          }
          // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        }
        &:disabled {
          & + label {
            opacity: 0.3;
            &:hover {
              cursor: not-allowed;
            }
          }
        }
      }
      label {
        // padding-left: 1rem;
        // padding-right: 1rem;
        height: 55px;
        width: 55px;
        background: #ffffff;
        border: 1px solid #d5d5d5;
        border-radius: 6px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        transition: all 0.3s ease-in-out;
        &:hover {
          cursor: pointer;
        }
        span {
          &.sachetPresentation {
            background-color: #000;
            -webkit-mask-image: url("../../assets/img/25-sachet.svg");
            mask-image: url("../../assets/img/25-sachet.svg");
            display: inline-block;
            width: 40px;
            height: 34px;
            mask-repeat: no-repeat;
            -webkit-mask-position: center;
            mask-position: center;
            -webkit-mask-repeat: no-repeat;
          }
          &.canPresentation {
            background-color: #000;
            -webkit-mask-image: url("../../assets/img/can.svg");
            mask-image: url("../../assets/img/can.svg");
            display: inline-block;
            width: 30px;
            height: 34px;
            mask-repeat: no-repeat;
            -webkit-mask-position-x: center;
            mask-position: center;
            -webkit-mask-repeat: no-repeat;
          }
          &.sachet25Presentation {
            background-color: #000;
            -webkit-mask-image: url("../../assets/img/24-sachet.svg");
            mask-image: url("../../assets/img/24-sachet.svg");

            display: inline-block;
            width: 30px;
            height: 34px;
            mask-repeat: no-repeat;
            -webkit-mask-position-x: center;
            mask-position: center;
            -webkit-mask-repeat: no-repeat;
          }
          // &:last-child {
          //   font-family: $main-font;
          //   font-size: 17px;
          // }
        }
      }
    }
    p.no-stock {
      font-family: $secondary-font-semibold;
    }
    &__quantity {
      margin-top: 0.8rem;
      p {
        color: #757575;
        font-size: 1rem !important;
      }
      button {
        background-color: #d8d8d8;
        border: 0;
        font-weight: bolder;
        height: 40px;
        font-size: 1.1rem;
        width: 40px;
        @include breakpoint($md) {
          &:hover {
            background-color: darken(#d8d8d8, 10%);
          }
        }
      }
      span {
        background-color: #f6f6f6;
        font-size: 1.1rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        text-align: center;
        width: 40px;
      }
    }
    &__price {
      margin: 10px 0 0 0;
      h4 {
        font-size: 2rem;
        font-family: $secondary-font-semibold;
      }
      p {
        font-size: 1.5rem !important;
        text-transform: unset !important;
        width: 90%;
        text-align: unset !important;
        font-family: $secondary-font-semibold;
        @include breakpoint($md-2) {
          font-size: 1.5rem !important;
        }
        @include breakpoint($xl) {
          font-size: 1.5rem !important;
        }
        &.no-price {
          font-size: 1rem !important;
        }
      }
      &-prices {
        p {
          color: #000 !important;
          font-family: $secondary-font-semibold;
          font-size: 1.5rem !important;
          &.old-price {
            color: #757575 !important;
            font-size: 1.2rem !important;
            margin-right: 10px;
          }
        }
        span {
          font-family: $secondary-font-semibold;
        }
      }
    }
    &__cta {
      margin-top: 0.8rem;
      .zIndex {
        position: relative;
        z-index: 999;
        background-color: #000 !important;
      }
      button {
        color: #fff;
        padding: 0.8rem 0;
        transition: all 0.3s ease-in-out;
        border: 0;
        width: 100%;

        @include breakpoint($md-2) {
          width: 48%;
          &:hover {
            box-shadow: 0 5px 5px 3px rgba(206, 206, 206, 0.5);
            transform: translateY(-5px);
          }
        }
        &:disabled {
          background-color: rgb(139, 139, 139) !important;
          &:hover {
            box-shadow: unset;
            transform: none;
            cursor: not-allowed;
          }
        }
        img {
          height: 20px;
        }
        &:first-of-type {
          background-color: darken(#77777a, 15%);
          @include breakpoint($md) {
            margin-right: 1rem;
          }
        }
        &:last-of-type {
          background-color: #000;
          margin-top: 0.5rem;
          @include breakpoint($md) {
            margin-top: 0;
          }
        }
      }
    }
    .keep-shopping {
      font-family: $secondary-font-semibold;
      text-transform: uppercase;
      letter-spacing: 1.4px;
      font-size: 0.9rem;
      border-bottom: 1px solid #000;
      width: max-content;
      padding-bottom: 0.3rem;
      margin: 0.5rem auto;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
