@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

// ------------ PRODUCT CARD ------------
.product-card {
  height: 100%;
  transition: all 0.2s ease-in-out;
  position: relative;
  display: flex;
  @include breakpoint($md) {
    &:hover {
      transform: translateY(-10px);
    }
  }
  a {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__discount-label {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #000;
    color: #fff;
    padding: 0.4rem 0.6rem;
    text-transform: uppercase;
    font-family: $secondary-font-semibold;
    font-size: 0.7rem;
    letter-spacing: 1.3px;
    z-index: 3;
  }
  &__media {
    flex: 0 1 auto;
    img {
      width: 100%;
      @include breakpoint($md) {
        height: 308px;
      }
      @include breakpoint($lg) {
        height: auto;
        max-height: 300px;
        max-width: 310px;
      }
    }
    @include breakpoint($md-down) {
      max-height: unset;
    }
    .slick-next,
    .slick-prev {
      background-color: #fff;
      border-radius: 100%;
      height: 18px;
      width: 18px;
      display: flex !important;
      align-items: center;
      justify-content: center;
      @include breakpoint($md) {
        height: 17.5px;
        width: 17.5px;
      }
      @include breakpoint($lg) {
        height: 23px;
        width: 23px;
      }
    }
    .slick-prev:before,
    .slick-next:before {
      color: #000 !important;
      font-size: 1.5rem;
      opacity: 1;
      @include breakpoint($md) {
        font-size: 1.5rem;
        opacity: 1;
      }
    }
    .slick-next {
      top: 49.5%;
      right: 10px;
      z-index: 2;
      @include breakpoint($md-down) {
        top: 50%;
        right: 10px;
      }
    }
    .slick-prev {
      top: 50.1%;
      left: 10px;
      z-index: 2;
      @include breakpoint($md-down) {
        top: 50%;
        left: 10px;
      }
    }
    .slick-dots {
      bottom: -18px;
      @include breakpoint($md-down) {
      }
    }
  }
  &__body {
    padding: 1rem 0 0 0;
    font-family: $secondary-font;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    @include breakpoint($md) {
      padding: 1rem 0.5rem 0 0.5rem;
    }
    &__product-name {
      font-family: $secondary-font-bold;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      font-weight: bolder;
    }
    &__product-description {
      margin: 10px 0 7px 0;
      @include breakpoint($md-down) {
        font-size: 0.95rem;
      }
      font-size: 0.95rem;
      line-height: 1.2rem;
    }
    &__product-details {
      line-height: 1.2;
      @include breakpoint($md) {
        line-height: 1.5;
        margin-top: auto;
      }
      p {
        // font-weight: bolder;
        font-size: 1rem;
        @include breakpoint($md) {
          font-size: 0.95rem;
        }
        &:nth-child(2n) {
          margin: 0.3rem 0;
        }
        span {
          color: #757575;
          margin-right: 7px;
          @include breakpoint($md-down) {
            display: block;
          }
        }
      }
    }
    &__product-price {
      margin: 8px 0;
      margin-top: 10px;
      @include breakpoint($md) {
        margin-top: auto;
      }
      p {
        text-align: center;
        font-weight: bolder;
        font-size: 1.2rem;
        line-height: 1.2;
        @include breakpoint($md) {
          font-size: 1.2rem;
          line-height: 1.3rem;
        }
        span {
          color: #757575;
          display: block;
          font-weight: lighter;
          font-size: 1rem;
        }
      }
      &-prices {
        display: flex;
        flex-direction: column;
        & > div {
          justify-content: center;
          align-items: center;
          p {
            font-size: 1rem;
            margin-right: 5px;
          }
          span {
            // background-color: rgb(78, 78, 78);
            // color: #fff;
            // padding: 0.2rem 0.5rem;
            color: #000;
          }
        }
      }
    }
  }
  &__cta {
    p {
      text-decoration: none;
      color: #000;
      &.product-card__cta__toProduct {
        align-items: center;
        display: flex;
        justify-content: center;
        width: 100%;
        border: 0;
        color: #fff;
        background-color: #000;
        padding: 10px 0;
        transition: all 0.2s ease-in-out;
      }
    }
    button {
      align-items: center;
      display: flex;
      justify-content: center;
      width: 100%;
      border: 0;
      color: #fff;
      background-color: #000;
      padding: 10px 0;
      transition: all 0.2s ease-in-out;
      @include breakpoint($md) {
        &:hover {
          background-color: lighten(#000, 5%);
          img {
            margin-left: 12px;
          }
        }
      }
      span {
        font-size: 1.1rem;
      }
      img {
        margin-left: 8px;
        transition: all 0.2s ease-in-out;
        @include breakpoint($md) {
          height: 19px;
        }
      }
      // &:first-of-type {
      //   background-color: #77777a;
      //   img {
      //     @include breakpoint($md) {
      //       height: 22px;
      //     }
      //   }
      //   @include breakpoint($md) {
      //     &:hover {
      //       background-color: darken(#77777a, 5%);
      //     }
      //   }
      // }
    }
  }
}
