@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.guest {
  &__wrapper {
    background-color: #f5f4f2;
    padding: 2rem 1rem;
    display: flex;
    align-items: center;
  }
  &__container {
    background-color: #fff;
    padding: 2rem 1rem;
    margin: 0 auto;
    position: relative;
    &::after {
      content: "";
      height: 2px;
      width: 100%;
      background-color: #efefef;
      position: absolute;
      top: 35.5%;
      left: 0;
      @include breakpoint($ipad-port) {
        height: 100%;
        width: 2px;
        top: 0;
        left: 48%;
        transform: translateX(-48%);
      }
      @include breakpoint($lg) {
        left: 48%;
      }
      @include breakpoint($xl) {
        left: 47%;
        transform: translateX(-47%);
      }
    }
    @include breakpoint($md) {
      padding: 3rem 2.1rem;
      width: 75%;
    }
    @include breakpoint($xl) {
      width: 55%;
    }
    &__guest-option {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      h4,
      p {
        text-align: center;
        @include breakpoint($ipad-port) {
          text-align: unset;
        }
      }
      p {
        margin: 1rem 0;
        @include breakpoint($ipad-port) {
          width: 95%;
        }
        @include breakpoint($md) {
          width: 90%;
        }
      }
      button {
        background-color: #000;
        color: #fff;
        border: 0;
        padding: 0.8rem 2rem;
        width: max-content;
        margin: 0 auto;
        @include breakpoint($ipad-port) {
          margin: unset;
        }
      }
    }
    &__user-option {
      margin-top: 3.8rem;
      @include breakpoint($ipad-port) {
        margin-top: 0;
      }
      h4,
      p {
        @include breakpoint($md-down) {
          text-align: center;
        }
      }
      p {
        margin: 0.6rem 0;
        @include breakpoint($md-down) {
          margin-top: 1rem;
        }
      }
      a {
        color: #000;
      }
      label {
        font-weight: bolder;
      }
      input {
        background-color: #fbfbfb;
        border: 1px solid #dad8d8;
        border-radius: 0;
        padding: 0.8rem 0.6rem;
      }
      button {
        background-color: #000;
        border: 0;
        color: #fff;
        font-size: 1.1rem;
        padding: 0.8rem 0;
        margin: 1rem 0;
        width: 100%;
      }
    }
  }
}
