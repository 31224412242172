@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.profile {
  &__wrapper {
  }
  &__hero {
    background-color: #fbfbfb;
    padding: 1.5rem 1rem;
    @include breakpoint($md) {
      padding: 2.2rem;
    }
  }
  &__container {
    background-color: #f5f4f2;
    padding: 1rem;
    @include breakpoint($md) {
      padding: 2.2rem;
    }
    .container-fluid {
      background-color: #fff;
      padding: 1rem;
      .nav.nav-tabs {
        .nav-item {
        }
        .nav-link {
          color: #989898;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          border: 1¿4px solid #fff;

          &.active {
            border: 0;
            border-bottom: 4px solid #000;
            color: #000;
          }
          &:hover {
            border-color: #fff;
            border-bottom: 4px solid #989898;
          }
        }
      }
    }
    .wishlist-item {
      display: flex;
      // margin-top: 1.5rem;
      height: 100%;
      background-color: #fbfbfb;
      border: 1px solid #f0f0f0;
      padding: 10px;
      &__media {
        display: flex;
        align-items: center;
        img {
          width: 112px;
          @include breakpoint($lg) {
            width: 150px;
          }
        }
      }
      &__info {
        margin-left: 0.8rem;
        p {
          &:first-of-type {
            text-transform: capitalize;
            font-weight: bolder;
          }
          span {
            color: #989898;
            font-size: 0.9rem;
          }
        }
        img {
          display: block;
        }
        a {
          color: #fff;
          background-color: #000;
          display: block;
          text-decoration: none;
          padding: 7px 10px;
          // margin-top: 10px;
          transition: all 0.2s ease-in-out;
          width: max-content;
          &:hover {
            box-shadow: 0 5px 5px 3px rgba(170, 170, 170, 0.5);
          }
        }
        button {
          border: none;
          background-color: unset;
          transition: all 0.1s ease-in-out;
          &:hover {
            transform: translateY(-5px);
          }
        }
      }
    }
    .order-item {
      background-color: #1d1d1b;
      color: #fff;
      // margin-top: 1.5rem;
      padding: 15px;
      height: 100%;
      & > div {
        ul {
          margin-bottom: 0;
        }
        &:not(:last-child) {
          display: flex;
          h6 {
            margin-right: 10px;
          }
        }
      }
    }
  }
}
