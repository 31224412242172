@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.checkout {
  &__wrapper {
    background-color: #f5f4f2;
    padding: 2.3rem 0;
  }
  &__container {
    @include breakpoint($md) {
      padding: 0 1.75rem;
    }
    &__form,
    &__summary {
      background-color: #fff;
      padding: 1.75rem 1rem;
      @include breakpoint($md) {
        padding: 1.75rem;
      }
    }
    &__form {
      p {
        border-bottom: 1px solid #efefef;
        margin: 10px 0;
        padding-bottom: 5px;
      }
      .payment-form {
        &__header {
          background: #000;
          padding: 0.8rem;
          margin-bottom: 1rem;
          @include breakpoint($md) {
            padding: 1rem;
          }
          h4 {
            color: #fff;
            margin-bottom: 0;
            font-size: 1rem;
            display: flex;
            align-items: center;
            font-family: $secondary-font;
            @include breakpoint($md) {
              font-size: 1.2rem;
            }
            span {
              background: #fff;
              height: 24px;
              width: 24px;
              display: inline-flex;
              text-align: center;
              border-radius: 50%;
              font-size: 1rem;
              align-items: center;
              justify-content: center;
              margin-right: 7px;
              color: #000;
            }
          }
        }
        &__card-section {
          margin-bottom: 1rem;
        }
        label {
          font-weight: bolder;
        }
        input {
          &:not([type="checkbox"]) {
            display: flex;
            background-color: #fbfbfb;
            border: 1px solid #dad8d8;
            border-radius: 0;
            padding: 0.8rem 0.6rem;
          }
        }
        select {
          display: flex;
          background-color: #fbfbfb;
          border: 1px solid #dad8d8;
          border-radius: 0;
          padding: 0.8rem 0.6rem;
        }
        button {
          background-color: #000;
          color: #fff;
          padding: 1.1rem 0;
          width: 100%;
          border: 0;
          transition: all 0.2s ease-in-out;
          &:hover {
            box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.3);
          }
          &:disabled {
            background-color: rgb(132, 132, 132);
          }
          position: relative;
          z-index: 99;
        }
      }
    }
    &__details {
      position: sticky;
      position: -webkit-sticky;
      top: 1rem;
      left: 0 !important;
      right: unset;
    }
    &__summary {
      // position: sticky;
      // position: -webkit-sticky;
      // top: 1rem;
      // left: 0 !important;
      // right: unset;
      @include breakpoint($md) {
        padding: 1.75rem 1rem;
      }
      h5 {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #efefef;
        padding-bottom: 10px;
        span {
          background-color: #000;
          color: #fff;
          border-radius: 50%;
          height: 22px;
          width: 22px;
          display: inline-flex;
          text-align: center;
          font-size: 0.9rem;
          align-items: center;
          justify-content: center;
          margin-left: 5px;
        }
      }
      &__products {
        &-list {
          @include breakpoint($lg) {
            max-height: 500px;
            overflow-y: scroll;
          }
          .product {
            display: flex;
            margin-top: 1rem;
            &:not(:first-child) {
              border-top: 1px solid #efefef;
              padding-top: 1rem;
            }
            &__media {
              img {
                height: 86px;
                @include breakpoint($ipad-port) {
                  height: 120px;
                }
                @include breakpoint($md) {
                  height: 86px;
                }
              }
            }
            &__details {
              padding-left: 10px;
              width: 100%;
              div {
                &:first-of-type {
                  p {
                    text-transform: uppercase;
                    font-family: $secondary-font-semibold;
                  }
                  h6 {
                    font-family: $secondary-font-semibold;
                    margin: 0.3rem 0;
                  }
                }
              }
              &__quantity {
                button {
                  background-color: #d8d8d8;
                  border: 0;
                  font-weight: bolder;
                  height: 35px;
                  width: 35px;
                  @include breakpoint($md) {
                    &:hover {
                      background-color: darken(#d8d8d8, 10%);
                    }
                  }
                }
                // span {
                //   background-color: #f6f6f6;
                //   display: inline-flex;
                //   align-items: center;
                //   justify-content: center;
                //   height: 35px;
                //   text-align: center;
                //   width: 35px;
                // }
              }
              &__cta {
                height: max-content;
                border: 0;
                background-color: unset;
              }
              &__info {
                p {
                  font-weight: bolder;
                  font-size: 0.9rem;
                  span {
                    color: #757575;
                    margin-right: 7px;
                  }
                }
              }
            }
          }
        }
        &__summary {
          margin-top: 0.9rem;
          border-top: 1px dashed #bababa;
          padding-top: 0.6rem;
          span {
            color: #757575;
          }
          p {
            font-family: $secondary-font-semibold;
            font-size: 1.1rem;
          }
        }
      }
    }
    &__coupon {
      background-color: #fff;
      margin-top: 1rem;
      padding: 1rem;
      @include breakpoint($md) {
      }
      p {
        &:first-of-type {
          font-family: $secondary-font-semibold;
        }
      }
      & > div {
        display: flex;
        margin-top: 0.5rem;
        input {
          width: 70%;
          padding-left: 0.7rem;
        }
        button {
          background-color: #000;
          border: 0;
          color: #fff;
          letter-spacing: 1.2px;
          width: 30%;
          padding: 0.7rem;
          margin-left: 0.7rem;
          @include breakpoint($md) {
            margin-left: 10px;
          }
        }
      }
    }
  }
}
.coupon-error {
  color: #c93f3f;
  font-family: $secondary-font-semibold;
  margin-top: 0.5rem;
  display: block;
}
