@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.home {
  &__wrapper {
  }
  &__hero {
    background-image: 
    // linear-gradient(
    //     180deg,
    //     rgba(0, 0, 0, 0.33),
    //     rgba(252, 252, 252, 0.075)
    //   ),
      url("../../assets/img/home__hero-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    padding: 2rem 1rem;
    @include breakpoint($md) {
      background-position: bottom;
      padding: 2rem;
      background-image: url("../../assets/img/home__hero-bg.jpg");
    }
    @include breakpoint($xl) {
      background-position: bottom;
    }
    h1,
    h2 {
      color: #fff;
      @include breakpoint($md) {
        width: 65%;
        text-align: center;
      }
    }
    h1 {
      font-size: 1.7rem;
      width: 100%;
      text-align: center;
      letter-spacing: 1.2px;
      @include breakpoint($md) {
        letter-spacing: unset;
        // text-align: unset;
        width: 50%;
        font-size: 4rem;
      }
    }
    h2 {
      font-size: 1.4rem;
      text-align: center;
      letter-spacing: 1.2px;
      @include breakpoint($md) {
        letter-spacing: unset;
        // text-align: unset;
        width: 50%;
        font-size: 4rem;
      }
    }
    &__br {
      background-color: #fff;
      height: 4px;
      margin: 1rem auto;
      width: 80px;
      @include breakpoint($md) {
        height: 5px;
        // margin: unset;
        margin-right: unset;
        margin-top: 2rem;
        margin-left: 240px;
        margin-bottom: 2rem;
      }
      @include breakpoint($lg) {
        margin-left: 280px;
      }
      @include breakpoint($xl) {
        margin-left: 420px;
      }
    }
    a {
      background-color: #fff;
      color: #000;
      font-size: 1rem;
      text-transform: uppercase;
      letter-spacing: 1.3px;
      font-family: $secondary-font-bold;
      text-decoration: none;
      padding: 1rem 3rem;
      display: block;
      width: max-content;
      margin: 2rem auto;
      transition: all 0.2s ease-in-out;
      @include breakpoint($md) {
        margin: 2rem 0 0 13.3rem;
        font-size: 1.2rem;
        &:hover {
          transform: translateY(-5px);
        }
      }
      @include breakpoint($xl) {
        margin-left: 21rem;
      }
    }
    &__wrapper {
      padding: 0.5rem;
      height: 34vh;
      @include breakpoint($md) {
        padding: 1.5rem 1.5rem 0 1.5rem;
        height: 585px;
      }
      @include breakpoint($xl) {
        height: 650px;
      }
    }
  }
}

.about-us {
  background-color: #f0f0f0;
  padding: 1rem 0;
  margin-bottom: 2rem;
  text-align: center;
  @include breakpoint($md) {
    text-align: unset;
    padding: 0;
  }
  &__media {
    background-image: url("../../assets/img/about-us.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    top: -15px;
    left: 0;
    position: relative;
    height: 250px;
    @include breakpoint($md) {
      top: 0;
      left: -51px;
      height: 450px;
    }
    @include breakpoint($lg) {
      height: 350px;
    }
    @include breakpoint($xl) {
      left: -301px;
      height: 450px;
    }
  }
  h2 {
    margin-bottom: 1rem;
  }
  p {
    &:nth-child(2n + 1) {
      margin-top: 10px;
      font-family: $secondary-font-bold;
    }
    @include breakpoint($md) {
      width: 80%;
    }
  }
  a {
    background-color: $dark-grey;
    color: #fff;
    text-decoration: none;
    padding: 0.8rem 2rem;
    display: block;
    margin: 1rem 0;
    text-align: center;
    transition: all 0.3s ease-in-out;
    @include breakpoint($md) {
      max-width: max-content;
      &:hover {
        transform: translateY(-5px);
      }
    }
  }
}

// ------------ COMMON STYLES SEASON PROMOTIONS, BEST SELLERS, ACCESSORIES ------------
.season-promotions,
.best-seller,
.accessories {
  .product-card {
    margin: 0 1rem;
  }
  .slick-dots {
    font-size: 0.7rem;
  }
  .container-fluid {
    padding: 0 1rem;
    @include breakpoint($md) {
      padding: 0 3rem;
    }
    .slick-prev:before,
    .slick-next:before {
      color: #000 !important;
      font-size: 2.5rem;
      @include breakpoint($md) {
        font-size: 2rem;
      }
    }
    .slick-next {
      @include breakpoint($md-down) {
        top: 55%;
        right: 10px;
        z-index: 9999;
      }
    }
    .slick-prev {
      @include breakpoint($md-down) {
        top: 55%;
        left: -6px;
        z-index: 9999;
      }
    }
    .slick-slider {
      .slick-list {
        .slick-track {
          & > div {
            // height: 623px;
            & > div {
              // margin: 0 1rem;
              // margin-left: 1rem;
              @include breakpoint($md) {
                height: 100%;
                // margin-right: 1rem;
                // a {
                //   height: 100%;
                // }
              }
            }
          }
        }
        .slick-track {
          display: flex !important;
          flex-direction: row;
          // @include breakpoint($md-down) {
          //   width: 6101px !important;
          // }
        }

        .slick-slide {
          height: inherit !important;
          position: relative;
          // @include breakpoint($md-down) {
          //   width: 322px !important;
          // }
        }
      }
      .slick-dots {
        bottom: -22px !important;
        li button {
          &::before {
            @include breakpoint($md) {
              font-size: 0.6rem;
            }
          }
        }
      }
    }
  }
}
.season-promotions__header,
.best-seller__header,
.accessories__header {
  display: flex;
  flex-direction: column;
  @include breakpoint($md) {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
  h3 {
    margin-bottom: 0.5rem;
    width: max-content;
    @include breakpoint($md) {
      font-size: 3rem;
      margin-bottom: 0;
    }
  }
}
.season-promotions__header__br,
.best-seller__header__br,
.accessories__header__br {
  height: 1.3px;
  background-color: #000;
  width: 40%;
  @include breakpoint($md) {
    width: 40vw;
  }
  @include breakpoint($lg) {
    width: 50%;
  }
}
.season-promotions__products,
.best-seller__products,
.accessories__products {
  margin-top: 1.5rem;
  a {
    color: #000;
    text-decoration: none;
  }
  &__all {
    position: absolute;
    top: 50%;
    @include breakpoint($md-down) {
      left: 35%;
    }
    a {
      margin-left: 7%;
      color: #000;
      text-transform: uppercase;
      font-weight: bolder;
      padding-bottom: 6px;
      letter-spacing: 1.5px;
      font-family: $secondary-font-bold;
      border-bottom: 2px solid #000;
      @include breakpoint($md-down) {
        font-size: 1.2rem;
        margin-left: auto;
      }
    }
  }
}

// ------------ INDIVIDUAL STYLES SEASON PROMOTIONS ------------
.season-promotions {
  padding: 2rem 0 3.5rem 0;
  @include breakpoint($md) {
    padding: 2rem 0 5rem 0;
  }
  &__header__br {
    @include breakpoint($md) {
      width: 30vw;
    }
  }
}

// ------------ INDIVIDUAL STYLES BEST SELLERS ------------
.best-seller {
  margin-bottom: 5rem;
}

// ------------ ACCESSORIES ------------
.accessories {
  margin-bottom: 5rem;
  &__header {
    &__br {
      width: 70%;
    }
  }
}

.accessory-border {
  background-color: #4b4b4b;
  height: 4px;
  width: 100%;
  margin-bottom: 1rem;
}

.modal {
  &.show {
    z-index: 9999999999999999;
  }
  &-dialog {
    max-width: unset;
    @include breakpoint($lg) {
      width: 790px;
    }
  }
  &-content {
    height: 390px;
    overflow: hidden;

    @include breakpoint($xl) {
      height: 460px;
    }
  }
  &-header {
    border: 0;
    position: relative;
    z-index: 9;
    overflow: hidden;
    @include breakpoint($md-down) {
      &::before {
        content: "";
        position: absolute;
        background-color: $dark-grey;
        width: 100%;
        height: 8px;
        top: 0;
        left: 0;
      }
    }
  }
  &-body {
    padding: 0;
    margin-top: -1rem;
    text-align: center;
    @include breakpoint($lg) {
      text-align: unset;
      margin-top: 0;
    }
    &__media {
      height: 230px;
      width: 100%;

      display: none;
      @include breakpoint($md) {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        display: block;
        background-position: center;
        margin-top: -48px;
        width: 95%;
        height: 130%;
      }
    }
    &__content {
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 0 1rem;
      @include breakpoint($lg) {
        padding: 2rem 2rem 0 1rem;
        margin-top: -48px;
      }
      h3 {
        font-size: 2rem;
        margin-bottom: 0.5rem;
      }
      p {
        // margin-bottom: 0.5rem;
        font-size: 1.1rem;
        &:first-of-type {
          margin-top: 1rem;
        }
      }
      img {
        height: 35px;
        margin-bottom: 1rem;
        animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000px;
      }
      a {
        background-color: #000;
        color: #fff;
        text-align: center;
        padding: 0.8rem 0.5rem;
        text-decoration: none;
        display: block;
        font-size: 1.1rem;
        margin-top: 1rem;
        @include breakpoint($md) {
          margin-top: 1.5rem;
        }
      }
      span {
        display: block;
        text-align: center;
        margin-top: 0.5rem;
        font-size: 0.9rem;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
