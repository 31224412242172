@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.forgot-password {
  &__wrapper {
    background-color: #fbfbfb;
    padding: 2rem 1rem;
    display: flex;
    align-items: center;
    @include breakpoint($md) {
      padding: 2rem 0;
      min-height: 50vh;
    }
  }
  &__container {
    background-color: #fff;
    border-bottom: 6px solid #000;
    padding: 2rem 1rem;

    @include breakpoint($md) {
      padding: 2.1rem;
      margin: 0 auto;
      width: 60%;
    }
    h4,
    p {
      text-align: center;
      // @include breakpoint($md-down) {
      // }
    }
    label {
      font-weight: bolder;
    }
    input {
      background-color: #fbfbfb;
      border: 1px solid #dad8d8;
      border-radius: 0;
      padding: 0.8rem 0.6rem;
    }
    button {
      background-color: #000;
      border: 0;
      color: #fff;
      font-size: 1.1rem;
      padding: 1rem 0;
      margin: 1rem 0;
      width: 100%;
      transition: all 0.3s ease-in-out;
      text-transform: uppercase;
      font-family: $secondary-font-semibold;
      letter-spacing: 1.3px;
      &:hover {
        box-shadow: 0 2px 5px 5px rgba(67, 67, 67, 0.15);
      }
    }
    form {
      .re-captcha {
        background-color: #fff;
        div {
          &:nth-child(3) {
            width: 700px !important;
          }
        }
      }
    }
  }
}
