@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.catalogue {
  &__wrapper {
  }
  &__hero {
    background-color: #f5f5f5;
    .container-fluid {
      padding: 2.1rem 1rem;
      @include breakpoint($md) {
        padding: 3.1rem 2rem;
      }
    }
    h3 {
      margin-bottom: 0.5rem;
      @include breakpoint($md) {
        font-size: 2rem;
      }
    }
  }
  &__products {
    padding: 2rem 0;
    @include breakpoint($md) {
      padding: 3rem 1rem;
    }
    .accessory-border {
      background-color: #4b4b4b;
      height: 4px;
      width: 100%;
      margin-bottom: 1rem;
    }
    .product-card {
      transition: all 0.2s ease-in-out;
      a {
        text-decoration: none;
        color: #000;
        width: 100%;
        p.product-card__cta__toProduct {
          align-items: center;
          display: flex;
          justify-content: center;
          width: 100%;
          border: 0;
          color: #fff;
          background-color: #000;
          padding: 10px 0;
          transition: all 0.2s ease-in-out;
        }
      }
      @include breakpoint($md) {
        &:hover {
          transform: translateY(-10px);
        }
      }
    }
  }
}
