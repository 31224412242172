@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.footer {
  &__perks {
    background-color: $light-beige;
    padding: 1.5rem 0;
    // margin-top: 1rem;
    @include breakpoint($md) {
      // margin-top: 0;
      padding: 1.5rem 0;
    }
    img {
      max-height: 40px;
      @include breakpoint($md) {
        max-height: 35px;
      }
    }
    h4 {
      margin: 0.5rem 0;
      font-size: 1.2rem;
      @include breakpoint($md) {
        font-size: 1.3rem;
      }
    }
    a {
      color: #000;
      text-decoration: none;
    }
  }
  &__container {
    padding: 2.25rem 0 0 0;
    @include breakpoint($md) {
      padding: 2.25rem 0 0 0;
    }
    img {
      display: block;
      max-height: 60px;
      margin: 0 auto 1rem auto;
      @include breakpoint($md) {
        margin: unset;
        margin-bottom: 0;
        max-height: 79px;
      }
    }
    h6 {
      font-size: 1.1rem;
    }
    ul {
      padding-left: 0;
      list-style: none;
      margin-bottom: 0;
      li {
        margin-top: 7px;
        a {
          color: #000;
          text-decoration: none;
          @include breakpoint($xl) {
            font-size: 1.1rem;
          }
        }
      }
    }
    &__bottom {
      border-top: 1px solid #d3d3d3;
      margin-top: 2rem;
      @include breakpoint($md) {
        margin-top: 3rem;
      }
      p {
        margin: 0.5rem 0;
        font-size: 0.85rem;
        @include breakpoint($md) {
          font-size: 1rem;
        }
      }
    }
  }
}
